import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Container } from 'components/container';
import { Paragraph } from 'components/paragraph';
import { CONSTANTS } from 'constants/styles/constants';
import { string } from 'prop-types';
import QuoteDownIcon from 'svgs/icons/ic-quote-down.svg';

const SWrapper = styled.div`
    display: flex;
    background-color: var(--gray-color-90);
    padding: clamp(3rem, 2.2958rem + 3.0047vw, 5rem) var(--section-clamp);
`;

const SQuoteWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const SQuoteIconWrapper = styled.div`
    margin-right: 1.5rem;
    min-width: 3.625rem;
    height: 3.625rem;
    path {
        fill: var(--primary-color);
    }
    ${CONSTANTS.MEDIA.max500`
        min-width: 1.563rem;
        height: 1.563rem;
  `}
`;

const SQuote = styled(Paragraph)`
    font-size: clamp(1.375rem, 1.243rem + 0.5634vw, 1.75rem);
    margin-bottom: 1.25rem;
    line-height: 1.3;
`;

const SAuthor = styled.span`
    font-size: 1.125rem;
    font-weight: 600;
    font-style: normal;
`;

const SPosition = styled(Paragraph)`
    font-size: 0.875rem;
`;

export const QuoteBannerSection = ({ quote, author, position, className }) => {
    return (
        <Container className={className}>
            <SWrapper>
                <SQuoteIconWrapper role="presentation">
                    <QuoteDownIcon width="100%" height="100%" />
                </SQuoteIconWrapper>
                <SQuoteWrapper>
                    <blockquote>
                        <SQuote>
                            <FormattedMessage id={quote} />
                        </SQuote>
                    </blockquote>
                    <SAuthor>
                        <FormattedMessage id={author} />
                    </SAuthor>
                    <SPosition>
                        <FormattedMessage id={position} />
                    </SPosition>
                </SQuoteWrapper>
            </SWrapper>
        </Container>
    );
};

QuoteBannerSection.propTypes = {
    quote: string.isRequired,
    author: string.isRequired,
    position: string.isRequired,
    className: string,
};

QuoteBannerSection.defaultProps = {
    className: '',
};
