import React from 'react';
import { AnimatedTechnologies } from 'components/animated-technologies';
import { Container } from 'components/container';
import { RowWithTextImg } from 'modules/row-text-img';

export const ExpertsSection = () => {
    return (
        <Container>
            <RowWithTextImg
                title="technologies-page.theTeamOfSoftware"
                descriptions={[
                    'technologies-page.weOfferCustom',
                    'technologies-page.weUseBestPractice',
                ]}
                img={<AnimatedTechnologies />}
            />
        </Container>
    );
};
